<template>
  <div class="pl-md-3 pl-xs-1 d-flex flex-row align-center">
    <v-toolbar-title class="mr-2">
      {{ heading }}
    </v-toolbar-title>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-md-4 ml-sm-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="menuAction('home')"
        >
          <v-icon>{{ iconBack }}</v-icon>
        </v-btn>
      </template>
      <span
        v-if="
          ['terminated.detailmac', 'terminated.detailmar','terminatedsppd.detailmar','terminatedsppd.detailmac'].includes($route.name)
        "
        >{{ $t("tooltip.back-to-terminated-list") }}</span
      >
      <span
        v-else-if="
          [
            'sent.detailmac',
            'sent.detailmar',
            'sentxnusantara.detailmar',
            'sentxnusantara.detailmac',
          ].includes($route.name)
        "
        >{{ $t("tooltip.back-to-sent-list") }}</span
      >
      <span
        v-else-if="
          ['revoke.detailmac', 'revoke.detailmar'].includes($route.name)
        "
        >{{ $t("tooltip.back-to-revoked-list") }}</span
      >
      <span v-else>{{ $t("tooltip.back-to-revision-list") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-md-4 ml-sm-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="menuAction('reload')"
        >
          <v-icon>{{ iconReload }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.reload") }}</span>
    </v-tooltip>
    <v-tooltip bottom v-if="canPrint">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-md-4 ml-sm-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="menuAction('print_preview')"
        >
          <v-icon>{{ iconPrinter }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.print-preview") }}</span>
    </v-tooltip>
    <v-menu
      bottom
      left
      v-if="memoIsSent && userIsPjpDir && actionMenu && actionMenu.length > 0"
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              class="ml-2"
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              color="blue lighten-2 ml-3"
            >
              <v-icon>{{ iconCog }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.other-menu") }}</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in actionMenu"
          :key="i"
          class="cursor-pointer memo-header-menu"
          @click="menuAction(item.command)"
        >
          <mdicon :name="item.icon_name" class="v-icon mr-2" />
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      bottom
      left
      v-if="!userIsKrani && enableAction && actionMenu && actionMenu.length > 0"
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :data-hint="$t('introjs.this-button-for-submit-action')"
              data-hintposition="middle-middle"
              v-show="enableAction"
              class="ml-sm-0 ml-md-2"
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              text
              icon
              color="blue lighten-2 ml-3"
            >
              <v-icon>{{ iconCog }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.select-action") }}</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, i) in actionMenu"
          :key="i"
          @click="menuAction(item.command)"
        >
          <mdicon :name="item.icon_name" class="v-icon mr-2" />
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  mdiReload,
  mdiSend,
  mdiDeleteAlertOutline,
  mdiElevatorUp,
  mdiBackburger,
  mdiPrinter,
  mdiCog,
  mdiCheckboxMarkedCircleOutline,
} from "@mdi/js";

export default {
  name: "header-left",
  components: {},
  props: {
    heading: {
      type: String,
      default: "",
    },
    enableAction: {
      type: Boolean,
    },
    actionMenu: {
      type: [Object, Array],
    },
  },
  data: () => ({
    iconBack: mdiBackburger,
    iconReload: mdiReload,
    iconCog: mdiCog,
    iconSend: mdiSend,
    iconDelete: mdiDeleteAlertOutline,
    iconSubmit: mdiElevatorUp,
    menus: [],
    iconPrinter: mdiPrinter,
    sentMenu: [],
    iconCheck: mdiCheckboxMarkedCircleOutline,
  }),

  computed: {
    ...mapState("user", ["currentUser"]),
    ...mapState("draft", ["singleRevision"]),
    userIsKrani() {
      if (!this.currentUser) return false;
      return ["KRANI"].includes(this.currentUser.level_aplikasi_elemen);
    },
    userIsPjp() {
      return ["PJP", "PJP_KANPUS"].includes(
        this.currentUser.level_aplikasi_elemen
      );
    },
    userIsPjpDir() {
      if (!this.currentUser) return false;
      return ["PJP", "PJP_KANPUS", "DIREKTUR"].includes(
        this.currentUser.level_aplikasi_elemen
      );
    },
    useMemoBom() {
      if (!this.singleRevision) return false;
      return parseInt(this.singleRevision.memo.is_bom) === 1;
    },
    memoIsSent() {
      if (!this.singleRevision) return false;
      return parseInt(this.singleRevision.memo.memo_status_id) === 100;
    },
    canPrint() {
      if (!this.singleRevision) return false;
      if (this.singleRevision.memo.status_aksi === "done") return true;
      return false;
    },
  },
  methods: {
    ...mapActions("draft", ["setSingleDraft"]),
    ...mapActions("inbox", ["setMemoValidationDialog"]),
    ...mapActions(["setEditSppd"]),
    menuAction(command, param) {
      console.log(this.$route.name)
      if (command === "edit_sppd_form")
        return this.setEditSppd({ id: this.$route.params.id, status: true });
      if (this.$route.name === "sentxnusantara.detailmar" && command === "home")
        return this.$emit("menuAction", { command: "home_sentxnusantara" });
      if (this.$route.name === "sentx.detailmar" && command === "home")
        return this.$emit("menuAction", { command: "home_sentx" });
      if (this.$route.name === "sent.detailmar" && command === "home")
        return this.$emit("menuAction", { command: "home_sent" });
      if (this.$route.name === "issuedsppd.detailmar" && command === "home")
        return this.$emit("menuAction", { command: "home_issuedsppd" });
      if (this.$route.name === "issuedsppd.detailmac" && command === "home")
        return this.$emit("menuAction", { command: "home_issuedsppd" });
      if (this.$route.name === "mysppd.detailmar" && command === "home")
        return this.$emit("menuAction", { command: "home_mysppd" });
      if (this.$route.name === "mysppd.detailmac" && command === "home")
        return this.$emit("menuAction", { command: "home_mysppd" });

      this.$emit("menuAction", { command: command, param: param });
    },
    compareSlug(val1, val2) {
      if (!val1 || !val2) return;
      let res1 = val1.replace("&only=", "");
      let res2 = val2.replace("filter_", "");
      return res1 === res2;
    },
  },
};
</script>
